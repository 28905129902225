import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/infrastructure/appSettings';
import { HeadService } from '../head.module/head.service';
import { LinkDefinition } from '../head.module/link.service';
import { environment } from 'src/environments/environment';

type MetaTag = {
  name?: string;
  property?: string;
  content: string;
  httpEquiv?:string;
};

@Injectable({
  providedIn: 'root'
})
export class MetaTagsServiceService {

  constructor() {}

    CreateSearchResultsMetaTags(propertyListings:any, headService: HeadService, listingMode: string, setNoIndex:boolean = false) {
      if (!propertyListings) return;
  
      const robots = propertyListings.robots;
      let metaTags: MetaTag[] = [
        { name: "description", content: robots.metaDescription },
        { httpEquiv: "content-language", content: robots.metaLanguage },
        { property: "og:title", content: robots.metaTitle },
        { property: "og:description", content: robots.metaDescription },
        { name: "twitter:description", content: robots.metaDescription },
        { property: "og:url", content: propertyListings.placeSearchCriteria.shareUrl },
        { property: "og:image:width", content: AppSettings.ogImageWidth },
        { property: "og:image:height", content: AppSettings.ogImageHeight },
      ];
  
      if (listingMode === 'map') {
        robots.metaRobots = "noindex, nofollow";    
        robots.metaTitle = "Map - " + robots.metaTitle;
      }

      if (setNoIndex) {
        robots.metaRobots = "noindex, nofollow";
      }
  
      if (this.hasValidImages(propertyListings)) {
        const firstImage = this.getFirstImageUrl(propertyListings);
        metaTags.push({ property: "og:image", content: firstImage });
      }
  
      if (robots.metaRobots) {
        metaTags.push({ name: "robots", content: robots.metaRobots });
      }
  
      const links = this.createLinkDefinitions(robots, propertyListings);
      headService.SetHead(robots.metaTitle, metaTags, links);
    }

    CreateNonIndexableMetaTags(pageTitle:string, description:string, headService: HeadService) {
      headService.SetHead(pageTitle, [
        { name: "robots", content: "noindex, nofollow" },
        { name: "description", content: description },
        { property: "og:description", content: description }
      ], null);
    }
  
    CreatePropertyDetailsMetaTags(propertyDetails: any, headService: HeadService) {
      if (propertyDetails) {
          var robots = propertyDetails.robots;
  
          var metaDescription = "{PLACE}. Marketed by {MARKETED_BY}."
              .replace("{PLACE}", propertyDetails.title + " in " + propertyDetails.address)
              .replace("{MARKETED_BY}", propertyDetails.agentName);
  
          var metaTitle = "Check out this property on Placebuzz!";
  
          var metaTags: Array<MetaTag> = [
              { name: "description", content: metaDescription },
              { name: "robots", content: robots.metaRobots },
              { property: "og:title", content: metaTitle },
              { property: "og:description", content: metaDescription },
              { name: "twitter:description", content: metaDescription },
              { property: "og:url", content: propertyDetails.shareUrl },
              
          ];
        
          var links: Array<LinkDefinition> = [
              { rel: "canonical", href: robots.canonicalUrl }
          ];

          if (propertyDetails.photos && propertyDetails.photos.length > 0) {
            var imageMD = []
         
            var firstImage  = propertyDetails.photos[0].replace("/photo/", "/c960x730/photo/");
            var firstImageWebPSrcSet = firstImage.replace(".jpg", ".webp").replace("c960x730", "c480x365") + " 1x, " + firstImage.replace(".jpg", ".webp") + " 2x, " + ")";
            
            links.push({rel:"preload", href:firstImage.replace("jpg","webp"), as:"image",imagesrcset:firstImageWebPSrcSet, imagesizes:"1x, 2x" });

            var imageUrl = "";

            for (var i = 0; i < propertyDetails.photos.length; i++) {
                imageUrl = propertyDetails.photos[i].replace("/photo/", "/c" + AppSettings.ogImageWidth + "x" + AppSettings.ogImageHeight + "/photo/");
                imageMD.push({ property: "og:image", content: imageUrl });
            }

            metaTags = metaTags.concat(imageMD);
            metaTags = metaTags.concat(
                { property: "og:image:width", content: AppSettings.ogImageWidth },
                { property: "og:image:height", content: AppSettings.ogImageHeight },
            );

            links.push({ rel: "image_src", href: imageUrl });
        }
  
          headService.SetHead(
              robots.metaTitle,
              metaTags,
              links);
      }
  }


    private hasValidImages(propertyListings:any): boolean {
      return propertyListings.listings && propertyListings.listings.length > 0 && propertyListings.listings[0].photos && propertyListings.listings[0].photos.length > 0;
    }
  
    private getFirstImageUrl(propertyListings:any): string {
      return  propertyListings.listings[0].photos[0].replace("/photo/", "/c960x730/photo/");
    }
  
    private createLinkDefinitions(robots:any, propertyListings:any): any[] {
      const links = [];
  
      if (robots.canonicalUrl) {
        links.push({ rel: "canonical", href: robots.canonicalUrl });
      }
      if (robots.next) {
        links.push({ rel: "next", href: robots.next });
      }
      if (robots.previous) {
        links.push({ rel: "previous", href: robots.previous });
      }
  
      if (this.hasValidImages(propertyListings)) {
        const firstImage = this.getFirstImageUrl(propertyListings);
        links.push({ rel: "preload", href: firstImage.replace("jpg", "webp"), as: "image", imagesrcset: this.createImageSrcSet(firstImage), imagesizes: "1x, 2x" });
      }
  
      return links;
    }
  
    private createImageSrcSet(image: string): string {
      return `${image.replace(".jpg", ".webp").replace("c960x730", "c480x365")} 1x, ${image.replace(".jpg", ".webp")} 2x`;
    }
  
   
}
